export const MainIssues = {
  issueItems: [
    { number: 1, name: 'Signature Not Present' },
    { number: 2, name: 'Inaccurate Content' },
    { number: 3, name: 'Missing' },
    { number: 4, name: 'Misclassified (Misfiled)' },
    { number: 5, name: 'Incomplete Metadata' },
    { number: 6, name: 'Alcoa Criteria Not Fullfill' },
  ],
  issueNumberToName(issueNumber) {
    const item = this.issueItems.find(
      (element) => element.number === issueNumber,
    );
    return item ? item.name : '';
  },
};
